import TopSection from "./sections/topSection";
import ProcessSection from "./sections/processSection";

export default function HomePage() {
  
  return (
    <div className="">
     
      <TopSection />
     <ProcessSection />
     
    </div>
  );
}
