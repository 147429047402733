import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationFR from "./assets/locales/fr/translation.json";
import translationES from "./assets/locales/sp/translation.json";
import translationDA from "./assets/locales/da/translation.json";
import translationNE from "./assets/locales/ne/translation.json";
import translationFI from "./assets/locales/fl/translation.json";
import translationCH from "./assets/locales/ch/translation.json";
import translationKO from "./assets/locales/ko/translation.json";

// const fallbackLng = ["en"];
const availableLanguages = ["en" , "fr" , "es" , "da", "ne" , "fi" , "ch" , "ko" ];

const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  },
  da: {
    translation: translationDA
  },
  ne: {
    translation: translationNE
  },
  fi: {
    translation: translationFI
  },
  ch: {
    translation: translationCH
  },
  ko: {
    translation: translationKO
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng : "en",
    detection: {
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["cookie", "localStorage"],
      languageOnly: true,
      checkWhitelist: true
    },
    load : 'languageOnly',
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
