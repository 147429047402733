import { openDB } from 'idb';
import axios from 'axios';

const DB_NAME = 'secure_app_db';
const STORE_NAME = 'access_tokens';

export const storeAccessToken = async ({accessToken , data}) => {
  try {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        db.createObjectStore(STORE_NAME);
      },
    });

    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    await store.put(accessToken, 'access_token');
    await store.put(data, 'data');

    await tx.done;
  } catch (error) {
    console.error('Error storing access token:', error);
  }
};

export const getAccessToken = async () => {
  try {
    const db = await openDB(DB_NAME, 1);
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);

    const accessToken = await store.get('access_token');

    await tx.done;

    return accessToken;
  } catch (error) {
    console.error('Error retrieving access token:', error);
    return null;
  }
};


export const deleteAccessTokenStore = async () => {
  try {
    const db = await openDB(DB_NAME, 1);
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Clear the store to delete all data
    await store.clear();

    // Delete the object store
    db.deleteObjectStore(STORE_NAME);

    await tx.done;
  } catch (error) {
    console.error('Error deleting access token store:', error);
  }
};