
const initialState = {
    basicDetails: {},
  };
  
  export const basicDetails = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_BASIC_DETAILS':
        return {
          ...state,
          basicDetails: action.payload,
        };
      default:
        return state;
    }
  };