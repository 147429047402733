import React from "react";

export default function Usd() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C4.02919 18 0 13.9708 0 9C0 4.02919 4.02919 0 9 0C13.9708 0 18 4.02919 18 9C18 13.9708 13.9708 18 9 18ZM12.6562 10.7741C12.6562 9.35494 11.7697 8.55338 9.69581 8.05387V5.55638C10.3371 5.688 10.9508 5.96419 11.5509 6.39787L12.3154 5.16263C11.5624 4.61111 10.678 4.26666 9.75037 4.16362V3.375H8.5635V4.13719C6.858 4.26881 5.69869 5.24137 5.69869 6.66056C5.69869 8.14556 6.62625 8.86837 8.61806 9.36787V11.9441C7.731 11.8125 6.99469 11.4182 6.21675 10.8135L5.34375 12.0094C6.27822 12.7287 7.39163 13.1789 8.5635 13.311V14.625H9.75037V13.3239C11.4829 13.1794 12.6562 12.2197 12.6562 10.7741ZM8.6175 7.77769C7.56731 7.47563 7.30856 7.12069 7.30856 6.56831C7.30856 5.99062 7.75856 5.5305 8.61806 5.46469L8.6175 7.77769ZM11.0464 10.8658C11.0464 11.4969 10.5553 11.9306 9.69581 11.9964V9.61762C10.7601 9.92025 11.0464 10.2746 11.0464 10.8664V10.8658Z"
        fill="#5C6A7A"
      />
    </svg>
  );
}
