import React from "react";

export default function ArrowCurve() {
  return (
    <svg
      viewBox="0 0 241 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00147 40.3255C31.0014 10.3259 147.001 -18.6745 239.002 17.3257"
        stroke="#BCBBBB"
        strokeWidth="1.2"
        strokeDasharray="7 7"
      />
      <path
        d="M228.371 21.8262L238.289 17.5138"
        stroke="#BCBBBB"
        strokeWidth="2"
      />
      <line
        x1="233.487"
        y1="6.10384"
        x2="239.581"
        y2="17.9728"
        stroke="#BCBBBB"
        strokeWidth="2"
      />
    </svg>
  );
}
