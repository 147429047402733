import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import "./i18n";
import "./i18nextInit";
import "./styles/global.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { basicDetails } from "./services/reducers/reducers";
import { ChakraProvider } from '@chakra-ui/react'
// import { Auth0Provider } from '@auth0/auth0-react';

const store = createStore(basicDetails);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider >
{/* 
      <Auth0Provider
        domain="testmyproject.us.auth0.com"
        clientId="ZkB0sCwbHmHI37gk73OiJ9JCSdFZdAAp"
        // useRefreshTokens={true}
        // cacheLocation={"localstorage"}
        // redirectUri={window.location.origin}
        // audience="backendforchekins"
        // scope="openid profile email"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "Backend chekins api",
          scope: "openid profile email read:current_user update:current_user_metadata"
        }}

      > */}
        <App />
      {/* </Auth0Provider> */}
    </ChakraProvider>
  </Provider>
);
