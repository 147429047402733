import React from "react";

export default function LoginIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="9" r="4" stroke="white" strokeWidth="1.5" />
      <ellipse
        cx="15"
        cy="20"
        rx="7"
        ry="4"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
