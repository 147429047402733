import React from "react";
import ArrowCurve from "../../../assets/svgs/arrowCurve";
import LoyalityCard from "../../../assets/svgs/loyalityicon";
import Lowprice from "../../../assets/svgs/lowprice";
import { useTranslation } from "react-i18next";

export default function ProcessSection() {
  const { t } = useTranslation();

  return (
    <section id="process" className="mt-[82px]">
      <div className="container font-normal text-base leading-7 flex flex-col px-4 lg:w-10/12 mx-auto mt-10 space-y-12 md:space-y-6 ">
        <div className="flex flex-col space-y-12 ">
          <div className="text-center items-center justify-center  md:text-left">
            <span className="text-finalblue font-bold text-sm">
              {t("Benefits")}
            </span>
            <h2 className="text-4xl font-bold font-homepage text-center text-bluedark md:text-left">
              {t("Book With Us")}
            </h2>
          </div>
        </div>
        <div className="flex flex-col-reverse md:space-y-0 space-y-5 md:flex-row">
          <div className="space-y-4 pt-4 md:pt-0 md:space-y-2 md:w-1/2">
            <p className=" text-center text-[15px] 2xl:text-[18px] text-[#5C6A7A] md:text-left">
              {t("Join Chekins as a member and unlock a world of benefits:")}
            </p>
            <ul className="list-disc px-4  leading-7 text-[15px] 2xl:text-[18px]">
            <li className="  font-[400] text-[#5C6A7A] md:text-left">
                {/* {t("Personalized Content:")} */}
                <span className="">
                Personalized & curated search results and recommendations based on your profile, travel history, and interests
                </span>

                {/* <span className="font-bold text-bluedark">
              {t("auto_rebook_at_a_lower_price")}
            </span> */}
              </li>
              <li className="   font-[400] text-[#5C6A7A] md:text-left">
                {/* {t("Auto-Rebook:")}{" "} */}
                <span className="">
                Auto-Rebook feature that automatically refunds you any price difference if rates drop, ensuring maximum savings.
                </span>
                {/* <span className="font-bold text-bluedark">
              {t("save_up_to_40%_vs_other_leading_providers")}
            </span> */}
              </li>
              <li className=" text-[#5C6A7A] font-[400] md:text-left ">
                {/* {t("Transparency & Exceptional Value:")}{" "} */}
                <span className="">
                Lowest prices that save you up to 40% compared to other online booking platforms.
                </span>
                {/* <span className="font-bold ">{t("commission_free_rates")}</span> */}
              </li>
              <li className="   font-[400] text-[#5C6A7A] md:text-left">
                {/* {t("Price:")}{" "} */}
                <span className="">
                No hidden fees and exceptional value through exclusive member-only rates and unparalleled savings.
                </span>
                {/* <span className="font-bold text-bluedark">
              {t("members_only_prices.")}
            </span> */}
              </li>
             
             
              {/* <span  className="  font-[400] text-[#5C6A7A]">if prices drop for your booking.</span>{" "}<span className="  font-[400] text-[#5C6A7A]"> You get to keep the savings!</span> */}
            </ul>
            {/* <p className="  text-bluedark md:text-left">
            {t("if_prices_drop_for_your_booking")}{" "}
            <span className="font-bold text-bluedark">
              {t("you_get_to_keep_the_savings_!")}{" "}
            </span>
          </p> */}
          </div>
          <div className="flex flex-col items-center justify-center md:w-1/2">
            <div className="flex flex-row relative items-start justify-center ">
              <div className="flex space-y-3   items-center flex-col">
                <div className="rounded-l-full  md:bg-transparent">
                  <div className="flex items-center space-y-2">
                    <div className="px-[25px] text-white rounded-full py-[24px] bg-[#1893F8]">
                      <LoyalityCard />
                    </div>
                  </div>
                </div>
                <h3 className="text-[15px] font-bold">{t("Join as member")}</h3>
                <div className="flex flex-col w-full justify-center items-center text-center">
                  <p className="text-bluedark  text-[13px]">
                    {t("Introductory offer $19.99")}
                  </p>
                  <p className="text-bluedark w-[76%] text-[13px]">
                    {t(
                      "Offer valid for a limited time"
                    )}
                  </p>
                </div>
              </div>
              <div className="lg:w-[179px] absolute top-0 sm:left-[42%] md:left-[38%]  xl:left-[19%] -z-10 w-[140px]">
                <ArrowCurve />
              </div>

              <div className="flex flex-col space-y-3  items-center  md:flex-col">
                <div className="rounded-l-full md:bg-transparent">
                  <div className="flex items-center space-x-2">
                    <div className="px-[25px] text-white rounded-full py-[24px] bg-[#1893F8]">
                      <Lowprice />
                    </div>
                  </div>
                </div>
                <h3 className=" text-[15px] text-center font-bold ">
                  {t("book_any_hotel")}
                </h3>
                <div className="text-bluedark px-3 text-center flex flex-col text-[13px]">
                  {t("Prices up to 20% - 40% cheaper")}
                  <span>{t("than Competitors")} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
