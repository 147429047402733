import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Footer from "../layouts/footer/Footer";
import HomePage from '../pages/HomePage/HomePage';

const ProjectRoutes = () => {
  return (
    <Router>
      {/* <NavBar /> */}
      {/* <ToastContainer /> */}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" exact component={HomePage} />
         
        </Switch>
        <Footer />

      </Suspense>
    </Router>
  );
};

export default ProjectRoutes;
